<template>
    <div class="supporters-warpper"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loadBusy"
        infinite-scroll-distance="200"
    >
        <template v-if="isLoad && listData.length">
            <div class="page_title">支持情况</div>
            <ul class="list-container" >
                <li class="cell-item"
                    v-for="(item, index) in listData"
                    :key="index"
                >
                    <a class="list_link" href="javascript:;">
                        <img v-if="item.face_url" class="lazy-load icon" v-lazy="item.face_url">
                        <img v-else class="lazy-load icon" src="./img/logo.png">

                        <div class="project_title">
                            <span class="user_name">{{ item.user_name }}</span>
                            <span class="money">{{ item.amount/100 }} 元</span>
                        </div>
                        <div class="attach">{{ item.new_suppor_date }}</div>
                        <div class="support_text">{{ item.comment }} </div>
                    </a>
                </li>
            </ul>
        </template>

        <div v-if="isLoad && !listData.length">
            <img src="./img/not-data.png" class="not-data" />
            <span class="not-data-desc">暂无支持记录</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utils from '@/common/js/utils';
import { getSupports } from './api';
import { shareProj } from '@/common/js/mmcModule';

import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

const urlParams = utils.getRequestParams();
export default {
    name:'Supporters',
    data () {
        return {
            isRequestLoad: false,
            loadBusy: false,
            isLoad: false,
            page_next_id: '',
            listData: [],
            nextPage: true,
            page_size: 10,
            logo: require('./img/logo.png'),
            projuuid: urlParams.projuuid,
        }
    },
    created() {
        const shareParams = {
            projuuid: this.projuuid
        };
        shareProj.init(shareParams);
    },

    methods: {
        loadMore() {
            if (!this.nextPage) return;
            if (this.isRequestLoad) return;
            this.loadBusy = true;
            this.isRequestLoad = true;
            showLoading('加载中')

            setTimeout(() => {
                this.getSupportList();
            }, 1000);
        },

        getSupportList() {
            const oldList = this.listData;
            const params = {
                projuuid: this.projuuid,
                page_next_id: this.page_next_id,
            }

            getSupports(params)
            .then(res => {
                hideLoading();
                if (res.code == 0) {
                    let currentList = res.data
                    if (currentList && currentList.length) {
                        this.isLoad = true;
                        currentList = this.timeFilter(currentList);
                        let newList = oldList.length > 0 ? oldList.concat(currentList) : currentList;
                        this.listData = newList;
                    }

                    if(!currentList || currentList && currentList.length < this.page_size) {
                        this.page_next_id = '';
                        this.nextPage = false;
                    } else {
                        this.page_next_id = res.next;
                        this.nextPage = true;
                    }
                } else {
                    // alertMsg(res.msg);
                }

                this.loadBusy = false;
                this.isRequestLoad = false;
            })
            .catch(err => {
                hideLoading();
                this.loadBusy = false;
                this.isRequestLoad = false;
            });
        },

        timeFilter(listData) {
            return listData.map(item => {
                item['new_suppor_date'] = utils.jsDateDiff(item.support_unix);
                return item;
            })
        }
    }
}
</script>

<style lang='less' scoped>
.supporters-warpper {
    padding: 30px 15px 15px;
}

.page_title {
    margin-bottom: 35px;
    font-size: 34px;
    font-weight: 700;
    color: #333333;
    line-height: 1;
    text-align: left;
}

.list-container {
    margin: 0;
    padding: 0;
}

.cell-item {
    list-style: none;
    margin-bottom: 35px;
    padding-left: 50px;
    position: relative;

    .list_link {
        display: block;
    }

    .icon {
        position: absolute;
        top: 2px;
        left: 0;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
    }

    .project_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
        .user_name {
            font-size: 17px;
            color: #333;
            font-weight: 600;
            padding-right: 20px;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .money {
            font-size: 16px;
            font-weight: 600;
            color: #009BFF;
            text-align: right;
            line-height: 1;
            flex-shrink: 0;
        }
    }

    .attach {
        text-align: left;
        font-size: 13px;
        color: #999;
        .status {
            float: right;
        }
    }

    .support_text {
        margin-top: 15px;
        text-align: justify;
        line-height: 26px;
        font-size: 17px;
        color: #333;
        word-break:break-all;
        display:-webkit-box;
        -webkit-line-clamp:5;
        -webkit-box-orient:vertical;
        overflow:hidden;
    }
}

.not-data {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto 10px;
}

.not-data-desc {
    font-family: PingFangSC-Light;
    font-size: 17px;
    color: #333333;
    text-align: center;
    line-height: 54px;
}
</style>
