
import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

/**
 * 更新项目状态
*/
const getSupports = function (params) {
    const { projuuid, page_next_id } = params;
    const url = `${baseurl}mmc/project/supports/${projuuid}?next=${page_next_id}`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/list.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }

    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

export {
    getSupports,
}
